import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Events from "../components/Sections/Events/UpcomingEvents"
import Header from "../components/Sections/CenteredHeader"

const EventsPage = () => {
  return (
    <Layout>
      <SEO
        title="Upcoming Events"
        description="Find upcoming events and volunteer opportunities to help get involved in our mission."
      />
      <Header
        head={"K.A.T.E. EVENTS"}
        body={
          "Events are a great way to get involved on a more personal level. Use the list below to find local events to attend or volunteer and help make a difference!"
        }
      />
      <Events />
    </Layout>
  )
}

export default EventsPage
