import React from "react"
import { styled } from "linaria/react"

const Wrapper = styled.section`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px auto;
  padding: 0 15px;
  max-width: 1000px;
  @media screen and (max-width: 768px) {
    margin: 75px auto 100px auto;
  }
  > h1 {
    font-size: 2.75rem;
    text-transform: uppercase;
    margin-bottom: 15px;
    @media Screen and (max-width: 768px) {
      font-size: 2.188rem;
    }
  }
  > hr {
    width: 100px;
    margin: auto;
    margin-bottom: 15px;
  }
  > p {
    font-size: 1rem;
    line-height: 1.95rem;
  }
`
const CenteredHeader = props => {
  return (
    <Wrapper>
      <h1>{props.head}</h1>
      <hr />
      <p>{props.body}</p>
    </Wrapper>
  )
}

export default CenteredHeader
