import React from "react"
import Events from "../../Elements/Events"
import { styled } from "linaria/react"

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    > h2 {
      text-align: center;
      font-size: 2.75rem;
      text-transform: uppercase;
      margin-bottom: 30px;
      @media screen and (max-width: 768px) {
        font-size: 2.188rem;
      }
    }
  }
`
const UpcomingEvents = () => {
  return (
    <Wrapper>
      <div>
        <h2>Upcoming Events</h2>
        <Events />
      </div>
    </Wrapper>
  )
}

export default UpcomingEvents
