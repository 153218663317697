import React from "react"
import { styled } from "linaria/react"
import Form from "../Elements/SubForm"
import Warning from "../../images/Icons/warning-symbol.svg"
const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  background: rgba(196, 196, 196, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 3px;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 65%;
  margin: auto;
  align-items: center;
  text-align: center;
  padding: 100px 15px 125px 15px;
  > h3 {
    font-size: 35px;
    text-transform: uppercase;
  }
  > p {
    margin-bottom: 75px;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`
const Events = () => {
  return (
    <Wrapper>
      <Content>
        <img src={Warning} />
        <h3>Covid 19</h3>
        <p>
          Unfortunately, events have temporarily been put on hold due to the
          Covid 19 pandemic. Sign up below to get notified when new events get
          added!
        </p>
        <Form />
      </Content>
    </Wrapper>
  )
}

export default Events
